<template>
  <div id="preloader">
    <img class="preloader-logo" src="@/assets/images/si-pay-logo.png" />
    <div class="spinner"></div>
    <span class="text-center">{{ title }}<br />proszę czekać</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "przetwarzanie danych",
    },
  },
};
</script>

<style scoped>
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 55;
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  background-color: #222736;
  justify-content: center;
  align-items: center;
}

.preloader-logo {
  width: 80px;
  position: absolute;
  top: 40%;
  bottom: 50%;
  left: calc(50%-20px);
  right: calc(50%-20px);
  -webkit-animation: rounde 1.2s infinite ease-in-out;
  animation: rounde 1.2s infinite ease-in-out;
}

@-webkit-keyframes rounde {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
}

@keyframes rounde {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.spinner {
  position: absolute;
  margin: auto;
  margin-top: -125px;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
  transform-origin: 50% 60%;
  transform: perspective(200px) rotateX(66deg);
  animation: spinner-wiggle 1.2s infinite;
}
.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  margin: -4px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 0.05;
  border: inherit;
  border-color: transparent;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}

.spinner:before {
  border-top-color: #ffffff;
}

.spinner:after {
  border-top-color: #222736;
  animation-delay: 0.3s;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  20% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.1;
  }
}
</style>
